import React from 'react';
import Content from '../components/Content';

import styles from './ContentBlock.module.scss';

const ContentBlock = ({ content }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.maxWidth}>
        <Content html={content} />
      </div>
    </div>
  );
};

export default ContentBlock;
