import React, { Fragment } from 'react';

import SimpleHelmet from '../components/SimpleHelmet';

import ContentBlock from '../views/ContentBlock';
import PageTitle from '../components/PageTitle';

import styles from './IndexPage.module.scss';

export const IndexPageTemplate = ({ heading, content }) => {
  return (
    <>
      <PageTitle title={heading} light />
      <section>
        <ContentBlock content={content} />
      </section>
    </>
  );
};

const IndexPage = ({ data }) => {
  const { frontmatter, html, fields } = data.markdownRemark;

  return (
    <Fragment>
      <SimpleHelmet
        seoTitle={frontmatter.seoTitle}
        seoDescription={frontmatter.seoDescription}
        seoUrl={fields.slug}
      />
      <IndexPageTemplate heading={frontmatter.heading} content={html} />
    </Fragment>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      fields {
        slug
      }
      frontmatter {
        heading
        seoTitle
        seoDescription
      }
    }
  }
`;
