import React from 'react';

import styles from './Content.module.scss';

const Content = ({ html }) => {
  return (
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default Content;
