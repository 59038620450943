import React from 'react';
import Helmet from 'react-helmet';

import Config from '../../gatsby-config';

const SimpleHelmet = ({ seoTitle, seoDescription, seoUrl, seoImage }) => {
  const url = seoUrl && seoUrl === '/' ? '/' : seoUrl.replace(/\/+$/, '');
  return (
    <Helmet>
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />

      {url && (
        <meta
          property="og:url"
          content={`${Config.siteMetadata.siteUrl}${url}`}
        />
      )}
      {seoImage && (
        <meta
          property="og:image"
          content={`${Config.siteMetadata.siteUrl}${seoImage.childImageSharp.fixed.src}`}
        />
      )}
    </Helmet>
  );
};

export default SimpleHelmet;
